import { useNavigation } from "@remix-run/react";
import { LogOutIcon } from "lucide-react";
import { ReactNode } from "react";
import { useBaseHomeUrl, useLogout } from "~/hooks";
import { useRootLoaderData } from "~/utils";
import { EllipsisVertIcon } from "./icons";
import { MonetaStudioLogo } from "./images";
import { IndeterminateProgress } from "./IndeterminateProgress";
import { MonetaStudioBrand } from "./MonetaStudioBrand";
import { Button, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "./ui";

export const DEFAULT_LAYOUT_NAVBAR_HEIGHT_PX = 48;

export const DefaultLayout = ({ children, homeUrl: homeUrlOverride }: { children: ReactNode; homeUrl?: string }) => {
  const navigation = useNavigation();
  const baseHomeUrl = useBaseHomeUrl();
  const { user } = useRootLoaderData();
  const logout = useLogout();

  const homeUrl = homeUrlOverride ?? baseHomeUrl;
  const showLoader = navigation.state === "loading" || navigation.state === "submitting";

  return (
    <div className="flex h-full flex-col">
      {showLoader && (
        <div className="fixed top-0 w-full">
          <IndeterminateProgress />
        </div>
      )}
      <nav
        className="flex shrink-0 items-center justify-between gap-4 px-2"
        style={{ height: `${DEFAULT_LAYOUT_NAVBAR_HEIGHT_PX}px` }}
      >
        {homeUrl ? <MonetaStudioBrand to={homeUrl} /> : <MonetaStudioLogo />}
        {user && (
          <div className="flex items-center gap-1">
            <span className="text-primaryText text-xl tracking-tight">{user.username}</span>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button icon type="button" variant="inverted" size="small">
                  <EllipsisVertIcon />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem onSelect={() => logout()}>
                  <LogOutIcon className="mr-2 h-4 w-4" />
                  Logout
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )}
      </nav>
      {children}
    </div>
  );
};
