import { useCallback } from "react";
import { useRootLoaderData } from "~/utils";

type LogoutFn = () => void;

export const useLogout = (): LogoutFn => {
  const { user } = useRootLoaderData();

  return useCallback(() => {
    if (!user) {
      return;
    }

    const form = document.createElement("form");
    form.method = "post";
    form.action = user.logoutUrl;
    form.style.display = "none";
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  }, [user]);
};
