import type { SVGProps } from "react";
const SvgEllipsisVertIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" {...props}>
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.333}
      clipPath="url(#ellipsis-vert-icon_svg__a)"
    >
      <path d="M8.5 8.667a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334M8.5 4a.667.667 0 1 0 0-1.333A.667.667 0 0 0 8.5 4M8.5 13.333A.667.667 0 1 0 8.5 12a.667.667 0 0 0 0 1.333" />
    </g>
    <defs>
      <clipPath id="ellipsis-vert-icon_svg__a">
        <path fill="#fff" d="M.5 0h16v16H.5z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgEllipsisVertIcon;
